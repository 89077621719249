import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';


const headerColor = '#240400';

const LuckyStarPage = ({ data }) => (
  <>
    <SEO
      title="Neosurf Casino Bonus at LuckyStar - 125% &amp; 125 Spins"
      description="Take advantage of the exclusive LuckyStar Casino Neosurf Casino Bonus. Deposit with Neosurf and get 125% Bonus and 125 Free Spins."
      alternateLanguagePages={[
        {
          path: 'casino/luckystar',
          language: 'en'
        },
        {
          path: 'casino/luckystar',
          language: 'fr'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="LuckyStar Online Casino – Neosurf-friendly Casino" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">LuckyStar Casino &amp; Exclusive Neosurf Bonus</h1>
            <p className="textColorLight">Guided by the motto "<strong>Happy players are the best advertisement</strong>", LuckyStar Casino represents a true star in the online gambling industry. Over 1000 casino games, wide-range of payment methods including Neosurf and award-winning customer support, guarantee a superb gaming experience. Join today and claim your <strong>Special NEOSURF Casino Bonus</strong>.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">LuckyStar's Special Neosurf Welcome Bonus</h2>
            <ul>
              <li>125% up to 500$/€ &amp; 125 wager-free Free Spins</li>
              <li>Free Spins are on The Golden Owl of Athena / Fruit Zen / Mega Gems<br />(the slot you first open from the offered ones)</li>
              <li>Use the <strong>code: SURF125+125</strong></li>
              <li>Minimum deposit is 20$/€<br />Rollover is times thirty (x30)</li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" target="_blank" rel="noopener noreferrer">TO LUCKYSTAR CASINO</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Top rated Neosurf Casino Bonus" />
        </div>
      </div>
    </div>
  </>
)

export default LuckyStarPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/luckystar/neosurf_lucky-star.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/luckystar/neosurf_lucky-star_promo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
